import { apiFilters } from '@/config/axios';
import {
	GET_WALLETS_BALANCE, GET_ALL_WALLETS_BALANCES, GET_WALLETS_TRANSACTIONS, CREATE_PAYOUT_ACCOUNT, UPDATE_PAYOUT_ACCOUNT_ALERT,
	GET_PAYOUT_ACCOUNT, CREATE_WITHDRAWAL, UPDATE_WALLET_PASSWORD, CANCEL_WITHDRAWAL, GET_WALLET_ALERTS,
	UPDATE_WALLET_ALERT, GET_POINTS_BALANCE, GET_POINTS_TRANSACTIONS, ADD_POINTS, CREATE_TRANSFER, GET_PAYOUT_METHODS, GET_POINTS_SUMMARIES,
	GET_USER_WALLETS_BALANCE, GET_USER_POINTS_BALANCE, DOWNLOAD_WALLET_BALANCES,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Wallets {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getBalance(options) {
		const { method, endpoint } = GET_WALLETS_BALANCE;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUserBalance() {
		const { method, endpoint } = GET_USER_WALLETS_BALANCE;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getBalances(options) {
		const { method, endpoint } = GET_ALL_WALLETS_BALANCES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateWalletPassword(userId, payload) {
		const { method, endpoint } = UPDATE_WALLET_PASSWORD;
		return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTransactions(walletType = '', options) {
		const { method, endpoint } = GET_WALLETS_TRANSACTIONS;
		return this.data[method](endpoint(walletType), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createPayoutAccount(userId) {
		const { method, endpoint } = CREATE_PAYOUT_ACCOUNT;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updatePayoutAccountCreatedAlert(userId) {
		const { method, endpoint } = UPDATE_PAYOUT_ACCOUNT_ALERT;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPayoutAccount(userId) {
		const { method, endpoint } = GET_PAYOUT_ACCOUNT;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	transferBalance(payload) {
		const { method, endpoint } = CREATE_WITHDRAWAL;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	cancelTransfer(transactionId, payload) {
		const { method, endpoint } = CANCEL_WITHDRAWAL;
		return this.data[method](endpoint(transactionId), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAlerts() {
		const { method, endpoint } = GET_WALLET_ALERTS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAlert(transactionId) {
		const { method, endpoint } = UPDATE_WALLET_ALERT;
		return this.data[method](endpoint(transactionId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPointsBalance() {
		const { method, endpoint } = GET_POINTS_BALANCE;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUserPointsBalance() {
		const { method, endpoint } = GET_USER_POINTS_BALANCE;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPointsTransactions(options) {
		const { method, endpoint } = GET_POINTS_TRANSACTIONS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	addPoints(options) {
		const { method, endpoint } = ADD_POINTS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createTransfer(payload) {
		const { method, endpoint } = CREATE_TRANSFER;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPayoutMethods(options) {
		const { method, endpoint } = GET_PAYOUT_METHODS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPointsSummaries(options) {
		const { method, endpoint } = GET_POINTS_SUMMARIES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadBalanceReport(data) {
		const { method, endpoint } = DOWNLOAD_WALLET_BALANCES;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Wallets;
